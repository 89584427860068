<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col class="exam-request">
        <validation-observer
          :ref="`${form.id}-form`"
          v-slot="{ invalid }"
          v-if="canEdit"
        >
          <div class="form-group">
            <validation-provider
              name="cirurgia"
              rules="required"
              v-slot="{ touched, errors }"
            >
              <multiselect
                v-if="!fields.custom"
                v-model="fields.cirurgia"
                id="cirurgia"
                :options="cirurgiasOftalmologicas"
                :option-height="38"
                :showLabels="false"
                :searchable="true"
                :allowEmpty="false"
                :showNoResults="true"
                placeholder="Nome da cirurgia"
                @search-change="value => search = value"
                :class="{ error: touched && errors.length > 0 && 1 === 2 }"
              >
                <template slot="caret">
                  <div class="chevron">
                    <ChevronDown />
                  </div>
                </template>
                <template slot="noResult">
                  Nenhum resultado encontrado
                </template>
                <template slot="afterList" >
                  <li @click="setCustom(true, search)" v-show="search?.length > 2">
                    <div class="multiselect__option custom-item">
                      <Plus class="icon" />
                      Adicionar cirurgia "{{ search }}"
                    </div>
                  </li>
                </template>
                <template slot="noOptions"> Nenhuma opção </template>
              </multiselect>

              <div class="custom-input" v-else>
                <b-button variant="link" @click="setCustom(false)">
                  Selecionar um item da lista
                </b-button>
                <input
                  autocomplete="off"
                  v-model="fields.cirurgia"
                  :readonly="!canEdit"
                  type="text"
                  class="form-control"
                  placeholder=""
                  :class="{ error: touched && errors.length > 0 && 1 === 2 }"
                />
              </div>
            </validation-provider>
          </div>

          <div class="laterality-container">
            <p>Lateralidade</p>
            <div class="laterality-content">
              <div class="laterality-input">
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Olho esquerdo"
                    v-model="fields.surgeryDataLaterality"
                  />Olho esquerdo
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Olho direito"
                    v-model="fields.surgeryDataLaterality"
                  />Olho direito
                </div>
                <div class="laterality-option">
                  <input
                    type="radio"
                    name="surgery-data-laterality"
                    value="Ambos os olhos"
                    v-model="fields.surgeryDataLaterality"
                  />Ambos os olhos
                </div>
              </div>

              <b-button
                variant="primary"
                block
                class="h-38 add-button"
                :disabled="invalid"
                @click="addItem"
              >
                Adicionar
              </b-button>
            </div>
          </div>
        </validation-observer>

        <b-row>
          <table class="table-eyecare with-laterality">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Lateralidade</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(value, index) of form.value" :key="index">
                <td>{{ value.cirurgia }}</td>
                <td>{{ value.surgeryDataLaterality }}</td>
                <td class="text-right">
                  <div class="more-with-menu">
                    <MoreVertical class="more-icon" />
                    <div class="menu">
                      <b-button
                        variant="link"
                        class="h-38 mt-27 button"
                        @click="editItem(value, index)"
                        :disabled="!canEdit"
                      >
                        Editar
                      </b-button>
                      <b-button
                        variant="link remove mb-0"
                        :disabled="!canEdit"
                        @click="removeItem(index)"
                      >
                        Remover
                      </b-button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import Plus from '@/assets/icons/plus.svg'
import MoreVertical from '@/assets/icons/more-vertical.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'

export default {
  components: {
    FormWrapper,
    Plus,
    ChevronDown,
    MoreVertical
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.dadosDaCirurgia,
    })
  },
  data() {
    return {
      fields: this.getDefaultFields(),
      search: '',
    }
  },
  inject: ['cirurgiasOftalmologicas'],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/dadosDaCirurgia', ['handleFields', 'handleProps']),
    setCustom(value, searchTerm) {
      this.fields.custom = value
      this.fields.cirurgia = value ? searchTerm : null
    },
    getDefaultFields() {
      return {
        custom: false,
        cirurgia: null,
        surgeryDataLaterality: "Ambos os olhos",
      }
    },
    addItem() {
      if (this.editingIndex === null && this.form.value.some(v => v.cirurgia.toLowerCase() === this.fields.cirurgia.toLowerCase())) {
        this.$toast.warning('Cirurgia já adicionado')
        return
      }

      const value = [...this.form.value]
      if (this.editingIndex !== null) {
        value.splice(this.editingIndex, 1)
      }

      value.push(this.fields)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
      this.fields = this.getDefaultFields()
      this.editingIndex = null
    },
    editItem(value, index) {
      this.fields = { ...value }
      this.editingIndex = index
    },
    removeItem(index) {
      const value = [...this.form.value]
      value.splice(index, 1)
      this.handleProps({ key: 'value', value })
      this.updateMedicalRecord(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.value.forEach((key,index) => {
        this.removeItem(index)
      });
    }
  },
}
</script>
<style lang="scss" scoped>
  .table-eyecare {
    padding: 16px;
    &.with-laterality {
        tr {
          th {
            width: 25%;
            max-width: 25%;
          }
        }
      }
  }
  .exam-request {
    display: flex !important;
    flex-direction: column !important;
    .multiselect {
      border: 1px solid #C6CEEB;
      border-radius: 8px;
    }
    .laterality-container {
      display: flex;
      flex-direction: column;
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 150%;
        color: #0C1D59;
      }
      .laterality-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .laterality-input {
          display: flex;
          flex-direction: row;
          gap: 24px;
          .laterality-option {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: center;
            font-family: 'Nunito Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 150%;
            color: var(--type-active);
            input {
              height: 20px;
              width: 20px;
            }
          }
        }
        .btn {
          width: auto !important;
          height: 38px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .multiselect {
    .custom-item {
      color: var(--blue-500);
      display: flex;
      align-items: center;
      font-weight: 600;
      .icon {
        width: 24px;
        height: 24px;
        fill: var(--blue-500);
        margin-right: 12px;
      }
    }
    .multiselect__single {
      @media(min-height: 1600){
        font-size: 1.6vh;
      }
      @media(max-height: 1600){
        font-size: 1vw;
      }
    }
  }
</style>
